import React from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { useState, useEffect } from 'react';
import CircularProgressIndicator from '../CustomComponents/CircularProgressIndicator';
import { TableSortLabel, TextField } from '@mui/material';
import { getComparator, tableSort } from '../../utils/utils';


interface Task {
    projectId: string;
    status: string;
    taskId: string
}

const API_BASE_URL = `${process.env.REACT_APP_API_ENDPOINT}`

const TasksTable = () => {
    const [tasks, setTasks] = useState<Task[]>([]);
    const { id } = useParams<{ id?: string }>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();
    const [order, setOrder] = useState<'asc' | 'desc'>('asc');
    const [orderBy, setOrderBy] = useState('');
    const [searchTerm, setSearchTerm] = useState('');

    const navigateToNextPage = (taskData: any) => {
        navigate(`/job/${id}/tasks/${taskData.taskId}`, { state: { data: taskData } });
    };
    const handleSortRequest = (property: keyof Task) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const convertedTasks: { [x: string]: string | number; }[] = tasks.map(task => ({
        projectId: task.projectId,
        status: task.status,
        taskId: task.taskId,
    }));

    const filteredData = convertedTasks.filter((row) =>
        Object.values(row).some((value) => String(value).toLowerCase().includes(searchTerm.toLowerCase()))
    );
    const sortedData = tableSort(filteredData, getComparator(order, orderBy));

    useEffect(() => {
        fetch(API_BASE_URL + '/jobs/' + id + '/tasks')
            .then(response => response.json())
            .then(data => {
                setTasks(data as Task[]);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setLoading(false);
                setError(error);
            });
    }, []);
    const pop = () => {
        navigate(-1);
    };
    if (loading) {
        return (<CircularProgressIndicator />);
    }

    if (error) {
        return (
            <div>
                <p>Error: {error.toString()}</p>
                <Button variant='outlined' color='error' onClick={pop}>Go Back</Button>
            </div>
        );
    }
    return (
        <div>
            <TextField
                label="Search"
                variant="outlined"
                value={searchTerm}
                onChange={handleSearch}
                style={{ marginBottom: '16px', width: "30%" }}
            />
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer component={Paper} sx={{ maxHeight: "70vh"}} style={{backgroundColor:'black'}}>
                    <Table >
                        <TableHead >
                            <TableRow>
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === 'projectId'}
                                        direction={orderBy === 'projectId' ? order : 'asc'}
                                        onClick={() => handleSortRequest('projectId')}
                                    >
                                        Project Id
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === 'taskId'}
                                        direction={orderBy === 'taskId' ? order : 'asc'}
                                        onClick={() => handleSortRequest('taskId')}
                                    >
                                        Task Id
                                    </TableSortLabel>

                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === 'status'}
                                        direction={orderBy === 'status' ? order : 'asc'}
                                        onClick={() => handleSortRequest('status')}
                                    >
                                        Status
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedData.map((task) => (
                                <TableRow key={task.projectId + '-' + task.taskId}>
                                    <TableCell>{task.projectId}</TableCell>
                                    <TableCell>{task.taskId}</TableCell>
                                    <TableCell>{task.status}</TableCell>
                                    <TableCell>
                                        <Button variant="outlined" color="primary" onClick={() => {
                                            const taskData: any = tasks.find(obj => obj.taskId === task.taskId);
                                            navigateToNextPage(taskData)
                                        }}>
                                            View
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    );
};

export default TasksTable;
