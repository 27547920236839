import Box from '@mui/material/Box';
import CreateJobMap from '../../components/CreateJobMap/CreateJobMap';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { GEOJSON } from '../../utils/data';
import { Divider } from '@mui/material';
import { createNewJob } from '../../services/AuthService';

interface FormData {
    jobName: string;
}
interface CreateJobMapProps { }
const CreateNewJob: React.FC<CreateJobMapProps> = () => {
    const navigate = useNavigate();
    const [geoJson, setGeoJson] = useState(GEOJSON);
    const [formData, setFormData] = useState<FormData>({
        jobName: ''
    });

    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    const handleMapDataUpdate = (updatedGeoJson: any) => {
        console.log('Updated geo json:', updatedGeoJson);
        setGeoJson(updatedGeoJson);
    };

    function areAllCoordinatesZero() {
        type CoordArray = (number | string)[];
        const coordinates: CoordArray[] = geoJson === undefined || geoJson.features.length == 0  ? GEOJSON.features[0].geometry.coordinates[0] : geoJson.features[0].geometry.coordinates[0];
        return coordinates.every(coordArray =>
            coordArray.every(element => element === 0)
        );
    }

    const validateForm = (): boolean => {
        const newErrors: { [key: string]: string } = {};

        if (formData.jobName.trim() === '') {
            newErrors.jobName = 'Job Name is required';
        }
        if (areAllCoordinatesZero()) {
            newErrors.geoJson = 'Job Location is required';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (validateForm()) {
            console.log('Form submitted:', formData);
            createNewJob({"name":formData.jobName,"geojson":geoJson},
            (data) => {
                navigate(-1);
            },(error)=>{ 
            navigate(-1);
             alert("Error in creating job : "+error)
            });
        }
    };
    const pop = () => {
        navigate(-1);
    };

    return (
        <div>
            <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
                <Box display="flex" justifyContent="space-between" alignItems="center" >
                    <Typography variant="h5" component="h1" gutterBottom>
                        Create New Job
                    </Typography>
                    <Box display="flex" justifyContent="flex-end">
                        <Button type='submit' variant="outlined" sx={{ margin: "10px", display: "inline-flex" }}>
                            Generate Sidewalks
                        </Button>
                        <Button variant="outlined" color='error' sx={{ margin: "10px", display: "inline-flex" }} onClick={pop}>
                            BACK
                        </Button>
                    </Box>
                </Box>
                <Divider/>
                
                <Box display='flex' justifyContent='center'>
                <div style={{width:'70%', display:'flex', flexDirection:'column', justifyContent:'center',alignContent:'center'}} >
                <Typography variant="subtitle1" component="h1" gutterBottom sx={{marginTop:'10px'}}>
                    Enter Job Name*
                </Typography>
                <TextField
                    placeholder='Enter Job Name'
                    variant="outlined"
                    name="jobName"
                    value={formData.jobName}
                    onChange={handleInputChange}
                    error={!!errors.jobName}
                    style={{ width: '100%', marginTop: '5px' }}
                />
                {errors.jobName && <div style={{ color: '#c3433c', fontSize:"12px", marginTop:"5px" }}>{errors.jobName}</div>}
                <Typography variant="subtitle1" component="h1" gutterBottom sx={{ marginTop: '20px' }}>
                    Select Job Location*
                </Typography>
                {errors.geoJson && <div style={{ color: '#c3433c', fontSize:"12px" }}>{errors.geoJson}</div>}
                <CreateJobMap onGeoJsonUpdate={handleMapDataUpdate} />
                </div>
                </Box>
            </form>
        </div>);
}

export default CreateNewJob;