import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import AttractionsIcon from '@mui/icons-material/Attractions';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link, useLocation, useParams } from 'react-router-dom';
import DashboardPage from '../Dashboard/Dashboard';
import Logo from '../../components/GaussianLogo';
import Jobs from '../Jobs/Jobs';
import JobDetails from '../JobDetails/JobDetails';
import Tasks from '../Tasks/Tasks';
import TaskDetails from '../TaskDetails/TaskDetails';
import CreateNewJob from '../Jobs/CreateNewJob';
import { AppTheme } from '../../theme/AppTheme';
import LoginPage from '../Login/LoginPage';
import { Button } from '@mui/material';
import { useAuth } from '../../hooks/useAuth';




const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    })
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

// const barlowFont = {
//     fontFamily: 'Lato',
// };
// const theme = createTheme({
//     palette: {
//         ochre: {
//             main: 'black',
//             light: 'white',
//             dark: 'black',
//             contrastText: 'black',
//         },
//         primary:{
//          main:   '#CF8907'
//         }
//     },

//     typography: {
//         h1: barlowFont,
//         h2: barlowFont,
//         h3: barlowFont,
//         h4: barlowFont,
//         h5: barlowFont,
//         h6: barlowFont,
//         subtitle1: barlowFont,
//         subtitle2: barlowFont,
//         body1: barlowFont,
//         body2: barlowFont,
//         caption: barlowFont
//     },
// });


export default function Root() {
    const [open, setOpen] = React.useState(false);
    const location = useLocation();
    const { id } = useParams<{ id?: string }>();
    const { taskid } = useParams<{ taskid?: string }>();
    const auth = useAuth();
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    const logout = () => {
        auth?.signout();
    };
    let currentPage;
    switch (location.pathname) {
        case '/jobs':
            currentPage = <Jobs />;
            break;
        case `/job/${id}`:
            currentPage = <JobDetails />;
            break;
        case `/job/${id}/tasks`:
            currentPage = <Tasks />
            break;
        case `/job/${id}/tasks/${taskid}`:
            currentPage = <TaskDetails />
            break;
        case '/createNewJob':
            currentPage = <CreateNewJob />
            break;
        case '/login':
            currentPage = <LoginPage />
            break;
        default:
            currentPage = <DashboardPage />;
    }

    return (
        <ThemeProvider theme={AppTheme}>
            <Box sx={{ display: 'flex' }}>
                <AppBar position="fixed" open={open} sx={{ bgcolor: "ochre.dark" }}>
                    <Toolbar sx={{ bgcolor: "ochre.dark" }}>
                        <IconButton
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{ mr: 2, ...(open && { display: 'none' }) }}
                        >
                            <MenuIcon color='primary' />
                        </IconButton>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                        >
                            <Logo />
                        </IconButton>
                        <Typography variant="h6" noWrap component="div" color="#CF8907">
                            Waylytyx App
                        </Typography>
                        <div style={{ flexGrow: 1 }} />
                        <Button variant="outlined" sx={{ margin: "10px", position: "relative" }} onClick={logout}>
                            LOGOUT
                        </Button>
                    </Toolbar>
                </AppBar>
                <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                            backgroundColor: '#1F212B'
                        },
                    }}
                    variant="persistent"
                    anchor="left"
                    open={open}
                >
                    <DrawerHeader sx={{ bgcolor: "ochre.dark" }}>
                        <IconButton onClick={handleDrawerClose} color='primary'>
                            {AppTheme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton>
                    </DrawerHeader>
                    <Divider />
                    <List>
                        <ListItemButton component={Link} to="/dashboard">
                            <ListItemText primary="Dashboard" />
                        </ListItemButton>
                        <Divider />
                        <ListItemButton component={Link} to="/jobs">
                            <ListItemText primary="Jobs" />
                        </ListItemButton>
                        <Divider />
                    </List>
                </Drawer>
                <Main open={open}>
                    <DrawerHeader />
                    <Box>{currentPage}</Box>
                </Main>
            </Box>
        </ThemeProvider>
    );
}