
/// Contains all util functions 

import { parseISO } from 'date-fns';
export function tableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const tableSortArray = array.map((el, index) => [el, index] as [T, number]);
    tableSortArray.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return tableSortArray.map((el) => el[0]);
}

export function isDateString(value: any): boolean {
    const date = new Date(value);
    return !isNaN(date.getTime());
  }

export function getComparator<Key extends keyof any>(
    order: 'asc' | 'desc',
    orderBy: Key
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string  }
  ) => number {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => ascendingComparator(a, b, orderBy);
  }
  
  export function descendingComparator<Key extends keyof any>(
    a: { [key in Key]: number | string},
    b: { [key in Key]: number | string},
    orderBy: Key
  ): number {
     // Check if the values are of type Date
    const valueA = parseDate(a[orderBy]);
    const valueB = parseDate(b[orderBy]);
    if (isDateString(valueA) && isDateString(valueB) ){
        return genericComparator(valueB, valueA)
       }
    // If not a Date, generic comparison
    return genericComparator(b[orderBy], a[orderBy]);
  }
  
  export function ascendingComparator<Key extends keyof any>(
    a: { [key in Key]: number | string},
    b: { [key in Key]: number | string },
    orderBy: Key
  ): number {
    const valueA = parseDate(a[orderBy]);
    const valueB = parseDate(b[orderBy]);
    // Check if the values are of type Date
    if (isDateString(valueA) && isDateString(valueB) ){
     return genericComparator(valueA, valueB)
    }
    // If not a Date, perform generic comparison
    return genericComparator(a[orderBy], b[orderBy]);
  }
  
  function parseDate(value: number | string): Date {
    if (typeof value === 'string') {
      return parseISO(value);
    } else {
      return new Date(); 
    }
  }
  function genericComparator(valueA: number | string | Date, valueB: number | string | Date): number {
    if (valueA > valueB) return 1;
    if (valueA < valueB) return -1;
    return 0;
  }