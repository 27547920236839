import React, { useReducer, useEffect } from 'react';
import { Theme, ThemeProvider } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import { useDispatch } from "react-redux";
import { useNavigate, useLocation, Navigate } from 'react-router-dom';
import { AppTheme } from '../../theme/AppTheme';
import { useAuth } from '../../hooks/useAuth';

type State = {
    username: string
    password: string
    isButtonDisabled: boolean
    helperText: string
    isError: boolean
};

const initialState: State = {
    username: '',
    password: '',
    isButtonDisabled: true,
    helperText: '',
    isError: false
};
type Action = { type: 'setUsername', payload: string }
    | { type: 'setPassword', payload: string }
    | { type: 'setIsButtonDisabled', payload: boolean }
    | { type: 'loginSuccess', payload: string }
    | { type: 'loginFailed', payload: string }
    | { type: 'setIsError', payload: boolean };

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'setUsername':
            return {
                ...state,
                username: action.payload
            };
        case 'setPassword':
            return {
                ...state,
                password: action.payload
            };
        case 'setIsButtonDisabled':
            return {
                ...state,
                isButtonDisabled: action.payload
            };
        case 'loginSuccess':
            return {
                ...state,
                helperText: action.payload,
                isError: false
            };
        case 'loginFailed':
            return {
                ...state,
                helperText: action.payload,
                isError: true
            };
        case 'setIsError':
            return {
                ...state,
                isError: action.payload
            };
    }
}
const LoginForm = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [state, dispatch] = useReducer(reducer, initialState);
    const auth = useAuth();


    useEffect(() => {
        if (state.username.trim() && state.password.trim()) {
            dispatch({
                type: 'setIsButtonDisabled',
                payload: false
            });
        } else {
            dispatch({
                type: 'setIsButtonDisabled',
                payload: true
            });
        }
    }, [state.username, state.password]);

    const handleLogin = () => {
        if (state.username !== '' && state.password !== '') {
            dispatch({
                type: 'setIsButtonDisabled',
                payload: true
            });
            auth?.signin({ "username": state.username, "password": state.password },
                (data) => {
                    dispatch({
                        type: 'setIsButtonDisabled',
                        payload: false
                    });
                }, (error) => {
                    dispatch({
                        type: 'setIsButtonDisabled',
                        payload: false
                    });
                    alert("Invalid credentials or Error in signing in")
                });

        } else {
            dispatch({
                type: 'loginFailed',
                payload: 'Incorrect username or password'
            });
        }
    };

    if (auth?.accessToken) {
        return <Navigate to="/" replace />;
    }

    const handleKeyPress = (event: React.KeyboardEvent) => {
        if (event.keyCode === 13 || event.which === 13) {
            state.isButtonDisabled || handleLogin();
        }
    };
    const handleUsernameChange: React.ChangeEventHandler<HTMLInputElement> =
        (event) => {
            dispatch({
                type: 'setUsername',
                payload: event.target.value
            });
        };

    const handlePasswordChange: React.ChangeEventHandler<HTMLInputElement> =
        (event) => {
            dispatch({
                type: 'setPassword',
                payload: event.target.value
            });
        }


    return (
        <ThemeProvider theme={AppTheme}>
            <form style={{
                display: 'flex',
                flexWrap: 'wrap',
                width: 500,
                paddingRight: 40
            }} noValidate autoComplete="off" >
                <Card style={{
                    marginTop: "100px",
                    width: 600,
                    backgroundColor: '#FFFFFF10'
                }} >
                    <CardHeader style={{ textAlign: 'center', }} title="Login" titleTypographyProps={{ color: "primary" }} />
                    <CardContent style={{ margin: "10px" }}>
                        <div>
                        </div>
                        <div>
                            <TextField
                                error={state.isError}
                                fullWidth
                                id="username"
                                type="email"
                                label="Username"
                                placeholder="Username"
                                margin="normal"
                                variant='standard'
                                color='primary'
                                onChange={handleUsernameChange}
                                onKeyPress={handleKeyPress}
                            />
                            <TextField
                                error={state.isError}
                                fullWidth
                                id="password"
                                type="password"
                                label="Password"
                                placeholder="Password"
                                margin="normal"
                                helperText={state.helperText}
                                onChange={handlePasswordChange}
                                onKeyPress={handleKeyPress}
                            />
                        </div>
                    </CardContent>
                    <CardActions style={{ marginBottom: "10px", margin: "15px" }}>
                        <Button
                            variant="outlined"
                            size="large"
                            color="primary"
                            onClick={handleLogin}
                            disabled={state.isButtonDisabled}
                            style={{
                                marginTop: '2px',
                                flexGrow: 1,
                                borderColor: '#CF8907',
                                color: '#CF8907'
                            }}
                        >
                            Login
                        </Button>
                    </CardActions>
                </Card>
            </form>
        </ThemeProvider>
    );
}
export default LoginForm;