import React from 'react';
import { TableCell, TableRow, Typography, LinearProgress, IconButton, Tooltip } from '@mui/material';
import { AccountTree, Map, DownloadOutlined, HowToReg } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

interface JobRowProps {
    job: { [x: string]: string | number };
}
const POSM_BASE_URL = "https://waylyticsposm.westus2.cloudapp.azure.com"
const TASKING_MANAGER_URL = "https://wayl-tasking-manager.web.app"

export const JobRow: React.FC<JobRowProps> = ({ job }) => {
    let stage: number = 1;
    const navigate = useNavigate();
    const displayableDate = (inputString: string | number) => {
        const date = new Date(inputString);
        return date.toLocaleString();
    }
    const downloadFile = (file: any) => {
        const separatedStrings = file.split('/');
        const finalUrl = "https://" + separatedStrings[2] + "/" + separatedStrings[3] + "/" + separatedStrings[4] + "/" +`${job.projectId}-0/edges.geojson`;
        console.log("final url ", finalUrl);
        window.location.href = finalUrl
    }
    const getStageString = (stage : number) => {
        if(stage === 5){
            return "Done"
        }
        return "In Progress"
    }
    const navigateToPosm = (changeSetId: any) => {
      const posmUrl = POSM_BASE_URL + `/changeset/${changeSetId}`
      if(changeSetId !== 0){
        window.open(posmUrl,'_blank','noopener,noreferer')
      }
    }
    const navigateToTaskingManger = (projectId: any) => {
        const tmUrl = TASKING_MANAGER_URL + `/projects/${projectId}`
        window.open(tmUrl,'_blank','noopener,noreferer')
      }

    if (typeof job.stage === 'number') {
        stage = job.stage;
    }

    return (
        <TableRow key={job.waylId}>
            {/* <TableCell size='small'>{job.waylId}</TableCell> */}
            <TableCell size='medium' align='left'>
                <Typography variant='body2'>{job.name}</Typography>
            </TableCell>
            <TableCell>{displayableDate(job.created_at)}</TableCell>
            <TableCell>
                <LinearProgress value={stage * 20} variant='determinate' />
                {getStageString(stage)}
            </TableCell>
            <TableCell>
                <IconButton color='primary' aria-label='tasks' sx={{
                    "&.Mui-disabled": {
                        color: "#c0c0c0"
                    }
                }} disabled={stage < 1} onClick={() => navigate(`/job/${job.waylId}`)} >
                    <AccountTree />
                </IconButton>
                <Tooltip title='View Analyzed data' arrow>
                    <span>
                        <IconButton color='primary' aria-label='view ' sx={{
                            "&.Mui-disabled": {
                                color: "#c0c0c0"
                            }
                        }} disabled={job.changeSetId === 0 || stage != 5} onClick={() => navigateToPosm(job.changeSetId)}>
                            <Map />
                        </IconButton>
                    </span>
                </Tooltip>

                <Tooltip title='Download the analyzed files' arrow>
                    <span>
                        <IconButton color='primary' aria-label='download' sx={{
                            "&.Mui-disabled": {
                                color: "#c0c0c0"
                            }
                        }} disabled={stage < 4} onClick={() => downloadFile(job.input_geo_file)}>
                            <DownloadOutlined />
                        </IconButton>
                    </span>
                </Tooltip>
                <Tooltip title='Verify the Generated Sidewalks' arrow>
                    <span>
                        <IconButton color='primary' aria-label='Verify Generated Sidewalks' sx={{
                            "&.Mui-disabled": {
                                color: "#c0c0c0"
                            }
                        }} disabled={stage < 2} onClick={() => navigateToTaskingManger(job.projectId)}>
                            <HowToReg />
                        </IconButton>
                    </span>
                </Tooltip>
            </TableCell>
        </TableRow>
    );
};
