
import React from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import makeStyles from '@mui/styles/makeStyles';
import { Card } from '@mui/material';
import TaskDetailsMap from '../../components/TaskDetailsMap/TaskDetailsMap';

/**
 * 
 * @returns 
 * Single tasks details recived from tasks page.
 * 
 */

const TaskDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const jsonData = location.state && location.state.data;
    

    const pop = () => {
        navigate(-1);
    };
    return (
        <div>
            <Card sx={{width:'100%',backgroundColor:"#E0E0E0"}}>
                <CardContent>
                    <div>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <div>
                                <strong style={{color:"black"}}>Task Details</strong>
                            </div>
                            <div>
                                <Button variant="outlined" sx={{ margin: "5px" }} onClick={pop}>
                                    BACK
                                </Button>
                            </div>
                        </Box>
                        <div style={{color:"black"}}>
                            <strong>Status</strong>: {jsonData.status}
                        </div>
                    </div>
                </CardContent>
            </Card>
            <TaskDetailsMap TaskDetails={jsonData}/>
        </div>
    );
};

export default TaskDetails;
