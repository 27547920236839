import React, { useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { GEOJSON } from '../../utils/data';



mapboxgl.accessToken = `${process.env.REACT_APP_MAP_KEY}`

interface CreateJobMapProps {
    onGeoJsonUpdate: (updatedGeoJson: Object) => void;
  }
  
  const CreateJobMap: React.FC<CreateJobMapProps> = ({ onGeoJsonUpdate }) => {
    const [zoom, setZoom] = useState(9);
    const [generatedPolygon, setGeneratedPolygon] = useState();

    useEffect(() => {
        const map = new mapboxgl.Map({
            container: 'mapBox',
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [-122.17890206994159, 47.620582354986226],
            zoom: zoom
        });

        const draw = new MapboxDraw({
            displayControlsDefault: false,
            controls: {
                polygon: true,
                trash: true,
                line_string: false,
                point: false
            },
        });
        map.addControl(
            new MapboxGeocoder({
                accessToken: mapboxgl.accessToken,
                mapboxgl: mapboxgl
            }),
            'top-left'
        );
        map.addControl(draw);
        map.addControl(new mapboxgl.NavigationControl());
        map.on('zoomend', () => {
            console.log('A zoomend event occurred.');
            setZoom(map.getZoom());
        });

        map.on('draw.create', updateArea);
        map.on('draw.delete', updateArea);
        map.on('draw.update', updateArea);
        map.on('draw.add', updateArea);

        function updateArea() {
            const data = draw.getAll();
            const geojson = JSON.stringify(data);
            const jsonObject = JSON.parse(geojson);
            setGeneratedPolygon(jsonObject)           
            if (jsonObject !== null) {
                onGeoJsonUpdate(jsonObject);
            } else {
                console.error("Element with ID 'json' not found");
            }    
        }
        return () => map.remove();
    },[]);
    return  (
        <div className='mapboxCon'>
          <div className='mapboxCon-container'>
            <div id='mapBox'/>
          </div>
        </div>
      );
}
export default CreateJobMap;