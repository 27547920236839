import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";


export default function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: '#333',
        color: 'white',
        textAlign: 'center',
        position: 'fixed',
        bottom: 0,
        width: '100%',
      }}
    >
      <Container>
        <Grid item xs={12} sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%'
        }}>
          <Grid>
            <Typography variant="h6" component="div" style={{ color: "#CF8907", marginLeft: "20px" }} >
              Gaussian Solutions
            </Typography>
            <p style={{ color: 'white', fontSize: "12px" }}> Instant sidewalks</p>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}