import { Route, Routes, BrowserRouter } from "react-router-dom";
import LoginPage from "./Login/LoginPage";
import DashboardPage from "./Dashboard/Dashboard";
import Root from "./Root/Root";
import AuthRoute from "../components/AuthRoute/AuthRoute";
import Jobs from "./Jobs/Jobs";
import JobDetails from "./JobDetails/JobDetails";
import Tasks from "./Tasks/Tasks";
import TaskDetails from "./TaskDetails/TaskDetails";
import CreateNewJob from "./Jobs/CreateNewJob";


function AppRouter() {
  return (
    // <BrowserRouter>
      <Routes>
        <>
        <Route path="/login" element={<LoginPage />} />
        <Route element={<AuthRoute />}>
          <Route path="/" element={<Root />}>
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path='/jobs' element={<Jobs />} />
            <Route path="/job/:id" element={<JobDetails />}/> 
            <Route path="/job/:id/tasks" element={<Tasks />}/>   
            <Route path="/job/:id/tasks/:taskid" element={<TaskDetails />}/>  
            <Route path='/createNewJob' element={<CreateNewJob />} />  
          </Route>
        </Route>
        </>
      </Routes>
    // </BrowserRouter>
  );
}
export default AppRouter;
