
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { jobs2 } from './../../utils/data';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Card, CardContent, makeStyles } from '@material-ui/core';
import JobDetailsMap from '../../components/JobDetailsMap/JobDetailsMap';
import { useState } from 'react';
import { useEffect } from 'react';
import CircularProgressIndicator from '../../components/CustomComponents/CircularProgressIndicator';

const useStyles = makeStyles({
    card: {
        width: '100%',
        backgroundColor:"#E0E0E0"
        // backgroundColor: 'lightblue',
    },
});

interface JobDetail {
    name: string
    id: string
    status: string
    projectId: string
}
const API_BASE_URL = `${process.env.REACT_APP_API_ENDPOINT}`
const TM_BASE_URL =  `${process.env.REACT_APP_TM_BASE_URL}`

const JobDetails = () => {
    const [jobDetail, setJobDetail] = useState<JobDetail>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const { id } = useParams<{ id?: string }>();
    const itemId = id;
    const navigate = useNavigate();
    const classes = useStyles();

    useEffect(() => {
        fetch(API_BASE_URL + '/jobs/' + id)
            .then(response => response.json())
            .then(data => {
                setJobDetail(data as JobDetail);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setLoading(false);
                setError(error);
            });
    }, []);


    const navigateToTasks = () => {
        navigate(`/job/${id}/tasks`);
    };
    const pop = () => {
        navigate(-1);
    };
    const openTaskManager = () => {
        console.log('Need to navigate')
        console.log(jobDetail?.projectId)
        const url = TM_BASE_URL+'/projects/'+jobDetail?.projectId
       const newWindow =  window.open(url,'_blank','noopener,noreferer')
        
    }

    if (loading) {
        return (<CircularProgressIndicator />);
    }

    if (error) {
        return (
            <div>
                <p>Error: {error.toString()}</p>
                <button onClick={pop}>Go Back</button>
            </div>
        );
    }
    return (
        <div>
            <Box display="flex" justifyContent="flex-end">
            <Button variant="outlined" sx={{ margin: "10px", display: "inline-flex" }} onClick={openTaskManager}>
                    View in Task Manager
                </Button>
                <Button variant="outlined" sx={{ margin: "10px", display: "inline-flex" }} onClick={pop}>
                    BACK
                </Button>
               
            </Box>
            <Card className={classes.card}>
                <CardContent>
                    <div>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <div>
                                <strong>Name</strong>:  {jobDetail?.name}
                            </div>
                            <div>
                                <strong>Tasks</strong>:
                                <Button variant="outlined" sx={{ margin: "5px" }} onClick={navigateToTasks}>
                                    View Tasks
                                </Button>
                            </div>
                            
                        </Box>
                        
                        <div>
                            <strong>Status</strong>: {jobDetail?.status}
                        </div>
                    </div>
                </CardContent>
            </Card>
            <JobDetailsMap JobDetails={jobDetail} />
        </div>
    );
};

export default JobDetails;
