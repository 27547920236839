import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AttractionsIcon from '@mui/icons-material/Attractions';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Footer from './Footer';
import LoginForm from './LoginForm';
import { makeStyles } from '@mui/styles';
import Link from '@mui/material/Link';
import Icon from '@mui/material/Icon';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { colors, Grid } from '@mui/material';
import Logo from './../../components/GaussianLogo';
import { AppTheme } from '../../theme/AppTheme';

declare module '@mui/material/styles' {
  interface Palette {
    ochre: Palette['primary'];
  }

  interface PaletteOptions {
    ochre?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    ochre: true;
  }
}
const barlowFont = {
  fontFamily: 'Lato',
};

export default function LoginPage() {
  return (
    <ThemeProvider theme={AppTheme}>
      <Box sx={{ flexGrow: 1 }}>
        <div style={{backgroundColor:"black"}}>
        <AppBar position="static" style={{backgroundColor:"black"}} >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <Logo />
            </IconButton>
            <Typography variant="h6" component="div" style={{color:"#CF8907", marginLeft:"20px"}} >
              Waylytyx App
            </Typography>
          </Toolbar>
        </AppBar>
        </div>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          color: 'white',
          height: '500px',
          left: "20px"
        }}>
          <div style={{ paddingLeft: "20px", width: "500px" }}>
            <h1 style={{ color: '#CF8907' }}>Generate Sidewalk Street maps using AI & ML</h1>
            <div>Generate sidewalks in an instant</div>
            <div> Select regions of interest</div>
            <div>Get sidewalk information for your applications</div>
            <div style={{ paddingTop: "50px", display: 'flex', alignItems: 'center' }}>
              <PlayCircleIcon color='primary' />
              <Link href={"/"} target="_blank" rel="noopener noreferrer" >
                See it in Action
              </Link>
            </div>
          </div>
          <LoginForm />
        </Box>
      </Box>
      <div>
        <Footer />
      </div>
    </ThemeProvider>
  );
}