
import React, { createContext, useState, useEffect, ReactNode } from 'react';
import axios from 'axios';



interface AuthContextProps {
  accessToken: string | null;
  signin: (
    credentials: { username: string; password: string },
    successCallback: (response: any) => void,
    errorCallback: (error: any) => void
  ) => Promise<void>;
  signout: () => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

const LOGIN_URL = `${process.env.REACT_APP_AUTH_URL}`
export const AuthContext = createContext<AuthContextProps | null>(null);

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [accessToken, setAccessToken] = useState<string | null>(null);

  useEffect(() => {
    const storedAccessToken = localStorage.getItem('access_token');
    if (storedAccessToken) {
      setAccessToken(storedAccessToken);
    }
  }, []);

  const signin = async ({ username, password }: { username: string; password: string }, successCallback: (response: any) => void,
  errorCallback: (error: any) => void) => {
    try {

      const options = {
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: {username,password},
        url: LOGIN_URL + 'login',
      };
      const response = await axios(options);
      const newAccessToken = response.data.access_token;
      setAccessToken(newAccessToken);
      localStorage.setItem('access_token', newAccessToken);
      successCallback(response);
    } catch (error) {
      errorCallback(error);
      console.error('Authentication error:', error);      
    }
  };

  const signout = () => {
    setAccessToken(null);
    localStorage.removeItem('access_token');
  };

  const contextValue: AuthContextProps = {
    accessToken,
    signin,
    signout,
  };

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
