import React from 'react';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useState, useEffect } from 'react';
import CircularProgressIndicator from '../CustomComponents/CircularProgressIndicator';
import { IconButton, TableSortLabel, TextField, Tooltip, } from '@mui/material';
import { getComparator, tableSort } from '../../utils/utils';
import { JobRow } from './JobsListRow';
import RefreshIcon from '@mui/icons-material/Refresh';

interface Job {
  waylId: string;
  name: string;
  status: string;
  created_at: string;
  input_geo_file: string;
  stage: number;
  projectId: string
  changeSetId: number
}
interface JobStatus {
  waylId: string;
  stage: number;
}

const API_BASE_URL = `${process.env.REACT_APP_API_ENDPOINT}`

const JobsListTable = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [jobs, setJobs] = useState<Job[]>([]);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const handleSortRequest = (property: keyof Job) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const convertedTasks: { [x: string]: string | number; }[] = jobs.map(job => ({
    waylId: job.waylId,
    status: job.status,
    name: job.name,
    created_at: job.created_at,
    input_geo_file: job.input_geo_file,
    stage: job.stage,
    projectId : job.projectId,
    changeSetId : job.changeSetId
  }));

  const filteredData = convertedTasks.filter((row) =>
    Object.values(row).some((value) => String(value).toLowerCase().includes(searchTerm.toLowerCase()))
  );
  const sortedData = tableSort(filteredData, getComparator(order, orderBy));

  const isJobDone = (jobStatus: string | number) => {
    return jobStatus == 'analyzed';
  }

  const handleRowUpdate = (updatedRow: JobStatus) => {
    console.log('Previous Jobs State:', jobs);
    setJobs(prevJobs => {
      const updatedJobs = prevJobs.map(singleJob => {
        return singleJob.waylId === updatedRow.waylId ? { ...singleJob, stage: updatedRow.stage } : singleJob;
      });
      console.log('Updated Jobs State:', updatedJobs);
      return updatedJobs;
    });
  }
  var socketReference : WebSocket | undefined = undefined;

  const initiateSocketHandling = () =>{
    socketReference = new WebSocket('wss://wayl-api.azurewebsites.net/ws');

    socketReference.onmessage = (event) => {
      console.log('WebSocket message received:', event.data);
      // Handle the received message
      const status = JSON.parse(event.data) as JobStatus
      if (status){
        handleRowUpdate(status);
      }
    }; 
    // WebSocket event listeners
    socketReference.onopen = () => {
      console.log('WebSocket connection opened');
    };
    socketReference.onclose = (event) => {
      console.log('WebSocket connection closed:', event);
    };

    socketReference.onerror = (error) => {
      console.error('WebSocket error:', error);
    };
  }

  /// Method to fetch jobs data from api
  const fetchData = () => {
    setLoading(true);
    fetch(API_BASE_URL + 'jobs?sort=status&order=1')
    .then(response => response.json())
    .then(data => {
      setJobs(data as Job[]);
      setLoading(false);
      initiateSocketHandling();
    })
    .catch(error => {
      console.error('Error fetching data:', error);
      setLoading(false);
      setError(error);  
    });
  }

  useEffect(() => {
    fetchData()
    // Cleanup function to close the WebSocket when the component unmounts
    return () => {
      socketReference?.close();
    };

  }, []);

  const pop = () => {
    navigate(-1);
  };

  if (error) {
    return (
      <div>
        <p>Error: {error.toString()}</p>
        <button onClick={pop}>Go Back</button>
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
       <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', marginBottom:"16px" }}>
      <TextField
        label="Search"
        variant="outlined"
        value={searchTerm}
        onChange={handleSearch}
        style={{width: "350px", marginRight:"5px" }}
      />
      <div >
        <Tooltip title='Refresh data' arrow>
          <span>
            <IconButton color='primary' aria-label='view ' sx={{
              "&.Mui-disabled": {
                color: "#c0c0c0"
              }
            }} disabled={loading} onClick={fetchData}>
              <RefreshIcon />
            </IconButton>
          </span>
        </Tooltip>
      </div>
      </div>
      {loading  ? <CircularProgressIndicator /> : <div style={{ minWidth: '60%' }}>
        <TableContainer component={Paper} style={{ padding: 20, backgroundColor: 'black' }}>
          <Table size='small' >
            <TableHead>
              <TableRow>
                <TableCell >
                  <TableSortLabel
                    active={orderBy === 'name'}
                    direction={orderBy === 'name' ? order : 'asc'}
                    onClick={() => handleSortRequest('name')}
                  >
                    Name
                  </TableSortLabel>
                </TableCell>
                <TableCell >
                  <TableSortLabel
                    active={orderBy === 'created_at'}
                    direction={orderBy === 'created_at' ? order : 'asc'}
                    onClick={() => handleSortRequest('created_at')}
                  >
                    Created On
                  </TableSortLabel>
                </TableCell>
                <TableCell >
                  <TableSortLabel
                    active={orderBy === 'status'}
                    direction={orderBy === 'status' ? order : 'asc'}
                    onClick={() => handleSortRequest('status')}
                  >
                    Status
                  </TableSortLabel>
                </TableCell>
                <TableCell >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData.map((job) =>
                <JobRow key={job.waylId} job={job} />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      } </div>
  );
};

export default JobsListTable;

