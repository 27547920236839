import { BrowserRouter } from 'react-router-dom';
import './App.css';
import AppRouter from './routes';
import AuthProvider from './components/AuthProvider/AuthProvider';

function App() {
  return (
    <div className="App">
    <BrowserRouter>
    <AuthProvider>
    <AppRouter />
    </AuthProvider>
    </BrowserRouter>
    </div>
  );
}

export default App;
