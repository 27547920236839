import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, Divider, Typography } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import mapboxgl from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import axios from 'axios'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import "./../JobDetailsMap/JobDetailsMap.scss"
import "./../JobDetailsMap/JobDetailMapBox.scss"
import JobDetailsToggleBtns from '../ToggleButtons/JobDetailsToggleBtns';
import { Padding } from '@mui/icons-material';


const API_BASE_URL = `${process.env.REACT_APP_API_ENDPOINT}`
const ACCESS_TOKEN = `${process.env.REACT_APP_MAP_KEY}`

const TaskDetailsMap = ({ TaskDetails: TaskDetails }: { TaskDetails: any }) => {
    const [zoomLevel, setZoomLevel] = useState(9);
    const [mapInstance, setMap] = useState<mapboxgl.Map | null>(null);
    const [selectedLayer, setSelectedLayer] = useState<string>('layer1');
    const [selectedStyle, setSelectedStyle] = useState('mapbox://styles/mapbox/satellite-v9');
    const mapStyles = [
        { label: 'Streets', value: 'mapbox://styles/mapbox/streets-v12' },
        { label: 'Satellite', value: 'mapbox://styles/mapbox/satellite-v9' },
    ];

    const handleStyleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedStyle(event.target.value);
    };

    useEffect(() => {
        mapboxgl.accessToken = ACCESS_TOKEN

        const coords_array = TaskDetails.geojson.geometry.coordinates;
        // Get the min max things
        const longitudes = coords_array.map((coords: any[]) => coords[0])
        const latitudes = coords_array.map((coords: any[]) => coords[1])
        const minLat = Math.min(...latitudes)
        const maxLat = Math.max(...latitudes)
        const minLon = Math.min(...longitudes)
        const maxLon = Math.max(...longitudes)
        const center = [(minLon + maxLat)/2, (minLat+maxLat)/2]
        const map = new mapboxgl.Map({
            container: 'map',
            style: selectedStyle,
            center: new mapboxgl.LngLat((minLon + maxLon)/2,(minLat+maxLat)/2),
            zoom: zoomLevel
        });
        
       
        const bounds = new mapboxgl.LngLatBounds(
            new mapboxgl.LngLat(minLon, minLat),
            new mapboxgl.LngLat(maxLon, maxLat)
        );
        map.fitBounds(bounds, { maxZoom: 16 });

        setMap(map);
        // const draw = new MapboxDraw({
        //     displayControlsDefault: false,
        //     controls: {
        //         polygon: true,
        //         trash: true,
        //         line_string: true,
        //         point: true
        //     }
        // });
        // map.addControl(draw);
        map.addControl(new mapboxgl.NavigationControl());

        map.on('zoomend', () => {
            setZoomLevel(map.getZoom());
        });
 
        map.on('load', () => {
            map.addSource('crossingsFile', {
                type: 'geojson',
                data: `${TaskDetails.crossingsFile}`,
            });

            map.addSource('sidewalksFile', {
                type: 'geojson',
                data: `${TaskDetails.sidewalksFile}`,
            });

            map.addSource('streetsFile', {
                type: 'geojson',
                data: `${TaskDetails.streetsFile}`,
            });
            map.addSource('edgesFile', {
                type: 'geojson',
                data: `${TaskDetails.edgesFile}`,
            });

            map.addSource('nodesFile', {
                type: 'geojson',
                data: `${TaskDetails.nodesFile}`,
            });
            map.addLayer({
                'id': 'layer1',
                'type': 'circle',
                'source': 'crossingsFile',
                'paint': {
                    'circle-color': 'blue',
                    'circle-radius': 2
                }
            });
            map.addLayer({
                'id': 'layer2',
                'type': 'line',
                'source': 'sidewalksFile',
                'paint': {
                    'line-color': 'green',
                    'line-width': 2
                }
            });
            map.addLayer({
                'id': 'layer3',
                'type': 'line',
                'source': 'streetsFile',
                'paint': {
                    'line-color': 'red',
                    'line-width': 2
                }
            });
            map.addLayer({
                'id': 'layer4',
                'type': 'line',
                'source': 'edgesFile',
                'paint': {
                    'line-color': 'orange',
                    'line-width': 2
                }
            });
            map.addLayer({
                'id': 'layer5',
                'type': 'circle',
                'source': 'nodesFile',
                'paint': {
                    'circle-color': 'yellow',
                    'circle-radius': 4
                }
            });
        });

        // const updateArea = () => {
        //     const data = draw.getAll();
        //     const geojson = JSON.stringify(data);
        //     const jsonObject = JSON.parse(geojson);
        // }
        // map.on('draw.create', updateArea);
        // map.on('draw.delete', updateArea);
        // map.on('draw.update', updateArea);

        return () => map.remove();
    }, [selectedLayer,selectedStyle])

    const setZoomOnRadioChange = (coords: any) => {
        const jsonSource= coords;
        fetch(jsonSource)
            .then((response) => response.json())
            .then((data) => {
                const coordinates: any[] = [];

                if (data.features) {
                    data.features.forEach((feature: { geometry: { coordinates: any; }; }) => {
                        if (feature.geometry && feature.geometry.coordinates) {
                            coordinates.push(feature.geometry.coordinates);
                        }
                    });
                    if (mapInstance) {
                        const flatMap = coordinates.flat();
                        const result = flatMap.reduce((ac,value)=>ac.concat(value),[])
                        const longitudes = result.map((coords: any[]) => coords[0])
                        const latitudes = result.map((coords: any[]) => coords[1])
                        const minLat = Math.min(...latitudes)
                        const maxLat = Math.max(...latitudes)
                        const minLon = Math.min(...longitudes)
                        const maxLon = Math.max(...longitudes)
                        const bounds = new mapboxgl.LngLatBounds(
                            new mapboxgl.LngLat(minLon, minLat),
                            new mapboxgl.LngLat(maxLon, maxLat)
                        );
                        mapInstance.fitBounds(bounds, { maxZoom: 16 });
                    }
                } else {
                    console.error('Invalid JSON data format. It should be a GeoJSON FeatureCollection.');
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }
    const handleLayerChange = (switchName: string, newState: boolean) => {
        if (mapInstance) {
            if (switchName == "crossingsFile") {
                if(newState == true){
                    mapInstance.setLayoutProperty('layer1', 'visibility', 'visible');
                }else{
                    mapInstance.setLayoutProperty('layer1', 'visibility', 'none');
                }  
            } else if (switchName == "sidewalksFile") {
                if(newState == true){
                    mapInstance.setLayoutProperty('layer2', 'visibility', 'visible');
                }else{
                    mapInstance.setLayoutProperty('layer2', 'visibility', 'none');
                } 
            } else if (switchName == "streetsFile") {
                if(newState == true){
                    mapInstance.setLayoutProperty('layer3', 'visibility', 'visible');
                }else{
                    mapInstance.setLayoutProperty('layer3', 'visibility', 'none');
                } 
            } else if (switchName == "edgesFile") {
                if(newState == true){
                    mapInstance.setLayoutProperty('layer4', 'visibility', 'visible');
                }else{
                    mapInstance.setLayoutProperty('layer4', 'visibility', 'none');
                } 
            }else {
                if(newState == true){
                    mapInstance.setLayoutProperty('layer5', 'visibility', 'visible');
                }else{
                    mapInstance.setLayoutProperty('layer5', 'visibility', 'none');
                } 
            }
        }
    };
    return (
        <div className='mapbox'>
            <div className='mapbox-container'>
                <div id='map' />
                <div id = 'menu'>
                        {mapStyles.map((style) => (
                            <label key={style.value}>
                                <input
                                    type="radio"
                                    value={style.value}
                                    checked={selectedStyle === style.value}
                                    onChange={handleStyleChange}
                                />
                                {style.label}
                            </label>
                        ))}
                    </div>
                <div className='json-content'>
                    <JobDetailsToggleBtns onSwitchChange={function (switchName: string, newState: boolean): void {
                        handleLayerChange(switchName, newState)
                    }} />
                    <Divider light/>
                    <Container >
                    <Typography variant='subtitle2'>
                        Please use the toggle buttons to show/hide the data
                    </Typography>
                    </Container>
                   
                </div>
            </div>
        </div>
    )
}

export default TaskDetailsMap;