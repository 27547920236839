
export const jobs = [
    { id: 1, name: 'Seattle' },
    { id: 2, name: 'Paris Central' },
    { id: 3, name: 'City of Montreal-Canada' },
  ];

  export const jobs2 = [
    {
        "waylId": "e389f5a5-6718-4101-96fb-5870a92cab72",
        "name": "sample-name",
        "input_geo_file": "https://waylyticsstorage.blob.core.windows.net/waylytics-files/e389f5a5-6718-4101-96fb-5870a92cab72/e389f5a5-6718-4101-96fb-5870a92cab72-input.geojson",
        "created_at": "2023-10-12T12:17:30.156000",
        "status": "uploaded",
        "crossing_tasks_file": "https://waylyticsstorage.blob.core.windows.net/waylytics-files/0d401f06-4d54-4371-aded-664ca7be071b/0d401f06-4d54-4371-aded-664ca7be071b_crossing_tasks.geojson",
        "sidewalks_tasks_file": "https://waylyticsstorage.blob.core.windows.net/waylytics-files/9ba13cbd-60f8-40ba-a7e6-81357aadc10c/9ba13cbd-60f8-40ba-a7e6-81357aadc10c_sidewalk_tasks.geojson"
    },
    {
        "waylId": "78d401f06-4d54-4371-aded-664ca7be071b",
        "name": "google-texas",
        "input_geo_file": "https://waylyticsstorage.blob.core.windows.net/waylytics-files/e389f5a5-6718-4101-96fb-5870a92cab72/e389f5a5-6718-4101-96fb-5870a92cab72-input.geojson",
        "created_at": "2023-10-12T12:17:30.156000",
        "status": "uploading",
        "crossing_tasks_file": "https://waylyticsstorage.blob.core.windows.net/waylytics-files/e389f5a5-6718-4101-96fb-5870a92cab72/e389f5a5-6718-4101-96fb-5870a92cab72-input.geojson",
        "sidewalks_tasks_file": "https://waylyticsstorage.blob.core.windows.net/waylytics-files/e389f5a5-6718-4101-96fb-5870a92cab72/e389f5a5-6718-4101-96fb-5870a92cab72-input.geojson"
    },
    {
        "waylId": "33d401f06-4d54-4371-aded-664ca7be071b",
        "name": "google-chicago",
        "input_geo_file": "https://waylyticsstorage.blob.core.windows.net/waylytics-files/e389f5a5-6718-4101-96fb-5870a92cab72/e389f5a5-6718-4101-96fb-5870a92cab72-input.geojson",
        "created_at": "2023-10-12T12:17:30.156000",
        "status": "to be uploaded",
        "crossing_tasks_file": "https://waylyticsstorage.blob.core.windows.net/waylytics-files/e389f5a5-6718-4101-96fb-5870a92cab72/e389f5a5-6718-4101-96fb-5870a92cab72-input.geojson",
        "sidewalks_tasks_file": "https://waylyticsstorage.blob.core.windows.net/waylytics-files/e389f5a5-6718-4101-96fb-5870a92cab72/e389f5a5-6718-4101-96fb-5870a92cab72-input.geojson"
    },
    {
        "waylId": "22d401f06-4d54-4371-aded-664ca7be071b",
        "name": "google-dallas",
        "input_geo_file": "https://waylyticsstorage.blob.core.windows.net/waylytics-files/e389f5a5-6718-4101-96fb-5870a92cab72/e389f5a5-6718-4101-96fb-5870a92cab72-input.geojson",
        "created_at": "2023-10-12T12:17:30.156000",
        "status": "processing",
        "crossing_tasks_file": "https://waylyticsstorage.blob.core.windows.net/waylytics-files/e389f5a5-6718-4101-96fb-5870a92cab72/e389f5a5-6718-4101-96fb-5870a92cab72-input.geojson",
        "sidewalks_tasks_file": "https://waylyticsstorage.blob.core.windows.net/waylytics-files/e389f5a5-6718-4101-96fb-5870a92cab72/e389f5a5-6718-4101-96fb-5870a92cab72-input.geojson"
    }
]

  export const tasks = [
    {
        "waylId": "544077d6-7d35-4a28-8b84-86b165988e71",
        "projectId": "5",
        "taskId": "1",
        "geojson": {
            "type": "Feature",
            "geometry": {
                "coordinates": [
                    [
                        [
                            -97.731024,
                            30.291505
                        ],
                        [
                            -97.730759,
                            30.292055
                        ],
                        [
                            -97.729562,
                            30.291892
                        ],
                        [
                            -97.729396,
                            30.291259
                        ],
                        [
                            -97.730457,
                            30.29062
                        ],
                        [
                            -97.731149,
                            30.290918
                        ],
                        [
                            -97.731024,
                            30.291505
                        ]
                    ]
                ],
                "type": "Polygon"
            },
            "properties": {
                "lockedBy": null,
                "mappedBy": null,
                "taskId": 1,
                "taskIsSquare": false,
                "taskStatus": "READY",
                "taskX": null,
                "taskY": null,
                "taskZoom": null
            }
        },
        "status": "generating osmnx files",
        "crossingsFile": "",
        "sidewalksFile": "",
        "streetsFile": "",
        "edgesFile": "",
        "nodesFile": ""
    },
    {
      "waylId": "894077d6-7d35-4a28-8b84-86b165988e72",
      "projectId": "6",
      "taskId": "2",
      "geojson": {
          "type": "Feature",
          "geometry": {
              "coordinates": [
                  [
                      [
                          -97.731024,
                          30.291505
                      ],
                      [
                          -97.730759,
                          30.292055
                      ],
                      [
                          -97.729562,
                          30.291892
                      ],
                      [
                          -97.729396,
                          30.291259
                      ],
                      [
                          -97.730457,
                          30.29062
                      ],
                      [
                          -97.731149,
                          30.290918
                      ],
                      [
                          -97.731024,
                          30.291505
                      ]
                  ]
              ],
              "type": "Polygon"
          },
          "properties": {
              "lockedBy": null,
              "mappedBy": null,
              "taskId": 1,
              "taskIsSquare": false,
              "taskStatus": "READY",
              "taskX": null,
              "taskY": null,
              "taskZoom": null
          }
      },
      "status": "generating osmnx files",
      "crossingsFile": "https://waylyticsstorage.blob.core.windows.net/waylytics-files/0d401f06-4d54-4371-aded-664ca7be071b/0d401f06-4d54-4371-aded-664ca7be071b_crossing_tasks.geojson",
      "sidewalksFile": "https://waylyticsstorage.blob.core.windows.net/waylytics-files/0d401f06-4d54-4371-aded-664ca7be071b/0d401f06-4d54-4371-aded-664ca7be071b_crossing_tasks.geojson",
      "streetsFile": "https://waylyticsstorage.blob.core.windows.net/waylytics-files/0d401f06-4d54-4371-aded-664ca7be071b/0d401f06-4d54-4371-aded-664ca7be071b_crossing_tasks.geojson",
      "edgesFile": "https://waylyticsstorage.blob.core.windows.net/waylytics-files/0d401f06-4d54-4371-aded-664ca7be071b/0d401f06-4d54-4371-aded-664ca7be071b_crossing_tasks.geojson",
      "nodesFile": "https://waylyticsstorage.blob.core.windows.net/waylytics-files/0d401f06-4d54-4371-aded-664ca7be071b/0d401f06-4d54-4371-aded-664ca7be071b_crossing_tasks.geojson"
  }
];
export const GEOJSON = {
    "type": "FeatureCollection",
    "features": [
      {
        "type": "Feature",
        "id": 0,
        "properties": {},
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [
                0,
                0
              ],
              [
                0,
                0
              ],
              [
                0,
                0
              ],
              [
                0,
                0
              ]
            ]
          ]
        }
      }
    ]
  };
