import React, { useState } from 'react';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Box } from '@material-ui/core';
import { Circle } from '@mui/icons-material';
import { Divider } from '@mui/material';

interface ToggleButtonsProps {
  onSwitchChange: (switchName: string, newState: boolean) => void;
}

const ToggleButtons: React.FC<ToggleButtonsProps> = ({ onSwitchChange }) => {
  const [switches, setSwitches] = useState({
    input_geo_file: true,
    crossing_tasks_file: true,
    sidewalks_tasks_file: true,
  });

  const handleSwitchChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const newState = event.target.checked;
    setSwitches({ ...switches, [name]: newState });
    onSwitchChange(name, newState); 
  };

  return (
    <div style={{margin:"20px"}} >
      <Box display='flex' justifyContent='space-between'  alignItems='center'>
      <FormControlLabel
        control={<Switch checked={switches.input_geo_file} onChange={handleSwitchChange('input_geo_file')} />}
        label="Input GeoJSON"
      />
      <Circle htmlColor='blue'/>
      </Box>
      <Divider/>
      <Box display='flex' justifyContent='space-between'  alignItems='center'>
      <FormControlLabel
        control={<Switch checked={switches.crossing_tasks_file} onChange={handleSwitchChange('crossing_tasks_file')} />}
        label="Crossing Tasks"
      />
       <Circle htmlColor='green'/>
      </Box>
      <Divider/>
      <Box display='flex' justifyContent='space-between'  alignItems='center'>
      <FormControlLabel
        control={<Switch checked={switches.sidewalks_tasks_file} onChange={handleSwitchChange('sidewalks_tasks_file')} />}
        label="Sidewalks Tasks"
        
      />

      <Circle htmlColor='#61ACFF'/>
      </Box>
      <Divider/>
    </div>
  );
};

export default ToggleButtons;
