import axios from "axios";

const API_URL = `${process.env.REACT_APP_API_ENDPOINT}`

export const getCurrentUser = () => {
  const userStr = localStorage.getItem("access_token");
  if (userStr) return JSON.parse(userStr);

  return null;
};

export const createNewJob = async ({ name, geojson }: { name: string; geojson: Object }, successCallback: (response: any) => void,
  errorCallback: (error: any) => void) => {
    const token = localStorage.getItem("access_token");
    try {
     const newJob = {
        method: 'POST',
        headers: {'Authorization': 'Bearer ' + token },
        data: {name,geojson},
        url: API_URL + 'tasks/generate',
      };
      const response = await axios(newJob);
      successCallback(response);
    } catch (error) {
      errorCallback(error);
      console.error('Authentication error:', error);      
    }
  };