import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import mapboxgl from 'mapbox-gl';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import "./JobDetailsMap.scss"
import "./JobDetailMapBox.scss"
import ToggleButtons from '../ToggleButtons/ToggleButtons';



const ACCESS_TOKEN = `${process.env.REACT_APP_MAP_KEY}`

const JobDetailsMap = ({ JobDetails }: { JobDetails: any }) => {
    const [zoomLevel, setZoomLevel] = useState(9);
    const [mapInstance, setMap] = useState<mapboxgl.Map | null>(null);
    const [selectedLayer, setSelectedLayer] = useState<string>('layer1');
    const [selectedStyle, setSelectedStyle] = useState('mapbox://styles/mapbox/streets-v12');
    const mapStyles = [
        { label: 'Streets', value: 'mapbox://styles/mapbox/streets-v12' },
        { label: 'Satellite', value: 'mapbox://styles/mapbox/satellite-v9' },
    ];

    const handleStyleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedStyle(event.target.value);
    };

    useEffect(() => {
        mapboxgl.accessToken = ACCESS_TOKEN
        const coords_array = JobDetails.input_geojson.features[0].geometry.coordinates[0];
        const longitudes = coords_array.map((coords: any[]) => coords[0])
        const latitudes = coords_array.map((coords: any[]) => coords[1])
        const minLat = Math.min(...latitudes)
        const maxLat = Math.max(...latitudes)
        const minLon = Math.min(...longitudes)
        const maxLon = Math.max(...longitudes)
        const center = [(minLon + maxLat) / 2, (minLat + maxLat) / 2]
        const map = new mapboxgl.Map({
            container: 'map',
            style: selectedStyle,
            center: new mapboxgl.LngLat((minLon + maxLon) / 2, (minLat + maxLat) / 2),
            zoom: zoomLevel
        });

        const bounds = new mapboxgl.LngLatBounds(
            new mapboxgl.LngLat(minLon, minLat),
            new mapboxgl.LngLat(maxLon, maxLat)
        );
        map.fitBounds(bounds, { maxZoom: 16 });
        setMap(map);
        map.addControl(new mapboxgl.NavigationControl());
        map.on('zoomend', () => {
            setZoomLevel(map.getZoom());
        });

        map.on('load', () => {
            map.addSource('input_geo_file', {
                type: 'geojson',
                data: `${JobDetails.input_geo_file}`,
            });

            map.addSource('crossing_tasks_file', {
                type: 'geojson',
                data: `${JobDetails.crossing_tasks_file}`,
            });

            map.addSource('sidewalks_tasks_file', {
                type: 'geojson',
                data: `${JobDetails.sidewalks_tasks_file}`,
            });

            map.addLayer({
                'id': 'layer1',
                'type': 'line',
                'source': 'input_geo_file',
                'paint': {
                    'line-color': 'blue',
                    'line-width': 2
                }
            });
            map.addLayer({
                'id': 'layer2',
                'type': 'line',
                'source': 'crossing_tasks_file',
                'paint': {
                    'line-color': 'green',
                    'line-width': 2
                }
            });
            map.addLayer({
                'id': 'layer3',
                'type': 'line',
                'source': 'sidewalks_tasks_file',
                'paint': {
                    'line-color': '#61ACFF',
                    'line-width': 2
                }
            });
        });
        return () => map.remove();
    }, [selectedLayer,selectedStyle])

    const handleLayerChange = (switchName: string, newState: boolean) => {
        if (mapInstance) {
            if (switchName === "input_geo_file") {
                if (newState === true) {
                    mapInstance.setLayoutProperty('layer1', 'visibility', 'visible');
                } else {
                    mapInstance.setLayoutProperty('layer1', 'visibility', 'none');
                }
            } else if (switchName === "crossing_tasks_file") {
                if (newState === true) {
                    mapInstance.setLayoutProperty('layer2', 'visibility', 'visible');
                } else {
                    mapInstance.setLayoutProperty('layer2', 'visibility', 'none');
                }
            } else {
                if (newState === true) {
                    mapInstance.setLayoutProperty('layer3', 'visibility', 'visible');
                } else {
                    mapInstance.setLayoutProperty('layer3', 'visibility', 'none');
                }
            }
        }
    };
    return (
        <div className='mapbox'>
            <div className='mapbox-container'>        
                <div id='map' />
                <div id = 'menu'>
                        {mapStyles.map((style) => (
                            <label key={style.value}>
                                <input
                                    type="radio"
                                    value={style.value}
                                    checked={selectedStyle === style.value}
                                    onChange={handleStyleChange}
                                />
                                {style.label}
                            </label>
                        ))}
                    </div>
                <div className='json-content'>
                    <ToggleButtons onSwitchChange={function (switchName: string, newState: boolean): void {
                        handleLayerChange(switchName, newState)
                    }} />
                </div>
            </div>
        </div>
    )
}

export default JobDetailsMap;