import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";

function AuthRoute() {
  const user = useAuth();
  const location = useLocation();
  if (user?.accessToken === null) {
     return <Navigate to="/login"  />;
  }
  return <Outlet />;
}

export default AuthRoute;