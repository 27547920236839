
import Card from '@material-ui/core/Card/Card';
import CardContent from '@material-ui/core/CardContent/CardContent';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { AppTheme } from '../../theme/AppTheme';


const DashboardPage = () => {
  const navigate = useNavigate();

  const navigateToJobs = () => {
    navigate(`/jobs`);
  };
  return (
    <div>
      <ThemeProvider theme={AppTheme}>
      <Card style={{backgroundColor:"#E0E0E0",  width: '100%' }}>
                <CardContent>
                    <div>
                        <Box>
                            <div>
                                <strong>Welcome to Dashboard!</strong>
                            </div>
                        </Box>
                        <div>
                        <Button variant="contained" color='primary' sx={{ marginTop: "10px" }} onClick={navigateToJobs}>
                                    View Jobs
                                </Button>
                        </div>
                    </div>
                </CardContent>
            </Card>
      </ThemeProvider>
    </div>
  );
};

export default DashboardPage;