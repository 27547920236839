
// import { createTheme } from '@mui/material/styles';
import JobsListTable from '../../components/JobListTable/JobListTable';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';


const Jobs = () => {
  const navigate = useNavigate();
  const navigateToCreateTask = () => {
    navigate(`/createNewJob`);
};
  return (
    <div>           
      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{margin:"10px"}}>
      <Typography variant="h4" component="h1" gutterBottom>
        Jobs
      </Typography>
      <Button variant="outlined" sx={{ margin: "10px", display: "inline-flex" }} onClick={navigateToCreateTask}>
        Create New Job
      </Button>
      </Box>
     <JobsListTable/>
    </div>
  );
};

export default Jobs;