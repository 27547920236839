
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useNavigate, useParams } from 'react-router-dom';
import TasksTable from '../../components/TasksTable/TasksTable';

// const barlowFont = {
//   fontFamily: 'Lato',
// };
// const theme = createTheme({
//   palette: {
//     ochre: {
//       main: '#666666',
//       light: '#666666',
//       dark: '#666666',
//       contrastText: '#666666',
//     },
//   },
// });

const Tasks = () => {
  const navigate = useNavigate();
  const pop = () => {
    navigate(-1);
};
  
  return (
    <div>     
      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{margin:"10px"}}>
      <Typography variant="h4" component="h1" gutterBottom>
      Tasks
      </Typography>
      <div>
        <Button variant="outlined"  sx={{margin:"10px"}}  onClick={pop}>
          BACK
        </Button>
      </div>
      </Box>
     <TasksTable/>
    </div>
  );
};

export default Tasks;