import React, { useState } from 'react';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Circle } from '@mui/icons-material';
import { Box } from '@material-ui/core';
import { Divider, Icon } from '@mui/material';

interface JobDetailsToggleBtnssProps {
    onSwitchChange: (switchName: string, newState: boolean) => void;
  }
  
  const JobDetailsToggleBtns: React.FC<JobDetailsToggleBtnssProps> = ({ onSwitchChange }) => {
    const [switches, setSwitches] = useState({
        crossingsFile: true,
        sidewalksFile: true,
        streetsFile: true,
        edgesFile: true,
        nodesFile: true
    });
  
    const handleSwitchChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const newState = event.target.checked;
      setSwitches({ ...switches, [name]: newState });
      onSwitchChange(name, newState); 
    };
  
    return (
      <div style={{marginLeft:"20px",marginRight:"30px", display:"grid"}}>
        <Box display='flex' justifyContent='space-between'  alignItems='center'>
        <FormControlLabel
          control={<Switch checked={switches.crossingsFile} onChange={handleSwitchChange('crossingsFile')} />}
          label="Crossings "
          
        />
        
        <Circle htmlColor='blue'/>
        
        </Box>
        <Divider light/>
        
        <Box display='flex' justifyContent='space-between' alignItems='center'>
        <FormControlLabel
          control={<Switch checked={switches.sidewalksFile} onChange={handleSwitchChange('sidewalksFile')} />}
          label="Sidewalks "
        />
         <Circle htmlColor='green'/>
        </Box>
        <Divider light/>
        <Box display='flex' justifyContent='space-between'  alignItems='center'>
        <FormControlLabel
          control={<Switch checked={switches.streetsFile} onChange={handleSwitchChange('streetsFile')} />}
          label="Streets"
        />
         <Circle htmlColor='red'/>
        </Box>
        <Divider light/>
        <Box display='flex' justifyContent='space-between'  alignItems='center'>
         <FormControlLabel
          control={<Switch checked={switches.edgesFile} onChange={handleSwitchChange('edgesFile')} />}
          label="Edges (AI)"
        />
        <Circle htmlColor='orange'/>

        </Box>
        <Divider light/>
        <Box display='flex' justifyContent='space-between'  alignItems='center'>
         <FormControlLabel
          control={<Switch checked={switches.nodesFile} onChange={handleSwitchChange('nodesFile')} />}
          label="Nodes (AI)"
        />
         <Circle htmlColor='yellow'/>
        </Box>
      </div>
    );
  };
  
  export default JobDetailsToggleBtns;
  
